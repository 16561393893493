import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const CHANNEL_BASE_FRAGMENT = `
  fragment channelBaseFragment on Channel {
    id
    uid
    schemaCode
    name
    description
    imageFileResource {
      ...fileResourceBaseFragment
    }
    thumbnailFileResource {
      ...fileResourceBaseFragment
    }
    _isBookmarked(myUid: "%authUser%")
    _isFollowed(myUid: "%authUser%")
    _followerCount
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
